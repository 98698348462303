import React from 'react'
import PopUp from "./Components/PopUp";

class Page extends React.Component{
    constructor(){
        super()
        this.state={
            filePath:null,
            lowerThirdData:[],
            logoPath:"../../logowat110px.png",
            showPopUp:false,
            txtValue:"",
            txtFile:""
        }
        this.handleInputChange=this.handleInputChange.bind(this)
        this.downloadCanvas=this.downloadCanvas.bind(this)
        this.changeField=this.changeField.bind(this)
        this.addRow=this.addRow.bind(this)
        this.deleteRow=this.deleteRow.bind(this)
        this.readtxt=this.readtxt.bind(this)
        this.addRecord=this.addRecord.bind(this)
        this.filterState=this.filterState.bind(this)
        this.canvas=React.createRef();
    }

    componentDidMount() {
        var logo=new Image()
        logo.src=this.state.logoPath
        setTimeout(()=>this.drawLowerThird(logo,"test","tester",()=>{}),1000)
    }


    downloadCanvas(name){
        /*
        if(window.navigator.msSaveBlob){
            window.navigator.msSaveBlob(this.inputRef.current.msToBlob(),`Banner '${this.state.title}'.png`)
        }else {
        */
         console.log("draw")
        var canvas = this.canvas.current
        this.download(canvas.toDataURL(),name)


    }

    download(url,name){
        var link = document.createElement('a')
        document.body.appendChild(link)
        link.href = url
        link.download = name
        link.click()
        document.body.removeChild(link)
    }

    downloadCSV(lijst){
        var resultaat="naam;functie\n"
        lijst.forEach(value=>{
            resultaat+=value.naam+";"+value.functie+"\n"
        })
        var blob= new Blob([resultaat])
        var blobUrl=URL.createObjectURL(blob)
        var datum=new Date()
        this.download(blobUrl,`namen ${datum.toTimeString()+" "+datum.toDateString()} .csv`)

    }

    readCSV(file,callBack){
        var reader = new FileReader();
        reader.onload = (e)=> {
            var content = reader.result;
            content=content.replace("\r\n","\n")
            //Here the content has been read successfuly
            var res=content.split("\n").slice(0,-1)

            var firstRule=res[0]

            //var fields=firstRule.split(";")
            var fields=["naam","functie"]
            res = res.slice(1)
            res=res.map(value1 => {
                var values=value1.split(";")
                var res={}
                values.forEach((value2, index) => {
                    res[fields[index]]=value2
                })
                return res
            })

            this.setState((oldState)=>{
                console.log(oldState.lowerThirdData)
                res=oldState.lowerThirdData.concat(res)
                console.log(res)
                return({lowerThirdData: res,csvFile:""})
            });
        }
        reader.readAsText(file);
    }


    handleInputChange(event) {
        var target = event.target;
        var value = target.type === 'checkbox' ? target.checked : target.value;
        var name = target.name;

        if(name==="csvFile"){
            this.readCSV(target.files[0])
        }else if(name==="txtFile" && value!==""){
            this.readtxt(target.files[0])
        }
        if(name==="txtFile"&& value===""){

            }else{
                console.log(value)
            this.setState({
                [name]: value
            });
        }

    }


    readtxt(file){
        var reader = new FileReader()
        reader.addEventListener('load',ev => {
            var content = reader.result
            this.setState({
                showPopUp:true,
                textFile: content
            })
        })
        reader.readAsText(file)
    }

    drawLowerThird(logo,name,functie,callBack){
        this.ExecuteAfterLoading([logo],()=>{
            var ctx=this.canvas.current.getContext("2d")

            var lengteVak=1200
            var hoogteVak=150
            var xPos=190
            var yPos=875
            var hoogteGrd=40
            var yPosGrd=yPos+(hoogteVak-hoogteGrd)




            ctx.fillStyle="white"
            ctx.fillRect(xPos,yPos,lengteVak,hoogteVak)


            var grd=ctx.createLinearGradient(0,yPosGrd,0,yPosGrd+hoogteGrd)
            grd.addColorStop(0,"white")
            grd.addColorStop(1,"red")
            ctx.fillStyle=grd
            ctx.fillRect(xPos,yPosGrd,lengteVak,hoogteGrd)

            var verwachtHoogte=110
            var scale= verwachtHoogte/logo.height

            var hoogteImage=verwachtHoogte
            var breedteImage=logo.width*scale
            var xPosImage=xPos+20
            var yPosImage=yPos+10

            ctx.drawImage(logo,xPosImage,yPosImage,breedteImage,hoogteImage)

            var xPosName=xPosImage+breedteImage+40
            var yPosName=yPos+50

            ctx.font="bold 30px Open Sans"
            ctx.fillStyle="black"
            ctx.fillText(name!==undefined?name:"",xPosName,yPosName)

            ctx.font="Semibold 30px Open Sans "
            ctx.fillStyle="#2e2e2e"
            ctx.fontweight=600
            var xPosFunctie= xPosName+20
            var yPosFunctie= yPosName+40
            ctx.fillText(functie!==undefined?functie:"",xPosFunctie,yPosFunctie)
            callBack()
        })
    }

    ExecuteAfterLoading(images,func){{
        if(images.length!==0){
            var image= images.pop()
            var afterLoaded= ()=>this.ExecuteAfterLoading(images,func)
            if (!image.complete){
                image.addEventListener('load',() => afterLoaded())
            }else{
                afterLoaded()
            }
        }else{
            func()
        }
    }
    }

    genereerLowerThirds(lijst,setIndex){

        var aantalNullen=this.state.lowerThirdData.length.toString().length
        var image=new Image()
        image.src=this.state.logoPath
        lijst.forEach((value,index) =>
        {
            setTimeout(()=>{
                this.drawLowerThird(image,value.naam,value.functie,
                    ()=>{
                    if(setIndex!==undefined){index=setIndex}
                    this.downloadCanvas(`${this.addZero(index+1,aantalNullen)} ${value.naam} | ${value.functie}.png`)
                    })
            },1000*index)
        })
    }

    addZero(getal,aantal){
        var res=getal
        for (let i = 0; i < aantal - getal.toString().length; i++) {
            res = "0"+getal
        }
        return res
    }

    filterState(){
        var data= this.state.lowerThirdData
        var filter=data.filter(value => {
            if (value.naam===""&&value.functie===""){
                return false
            }else {
                return true
            }
        })
        this.setState( {lowerThirdData: filter})
    }

    changeField(event){
        const target=event.target
        this.setState(oldState=>{
            var data = oldState.lowerThirdData
            var key=target.name.split(" ")
            key[1]=parseInt(key[1])
            if(key[1]===data.length){
                var item={}
                item[key[0]]=target.value
                data.push(item)
            }else{
                data[key[1]][key[0]]=target.value
            }

            return {lowerThirdData: data}

        },this.filterState)
    }

    addRecord(naam,functie){
        this.setState(oldState=>{
            var lijst=oldState.lowerThirdData
            lijst.push({naam:naam,functie:functie})
            return{lowerThridData:oldState.lowerThirdData}
        },this.filterState)
    }

    addRow(index){
        this.setState(oldState=>{
            var data= oldState.lowerThirdData
            var firstCut=data.slice(0,index+1)
            var secondCut=data.slice(index+1)
            firstCut.push({naam:"",functie:""})
            firstCut=firstCut.concat(secondCut)
            return {lowerThirdData: firstCut}
        })
    }

    deleteRow(index){
        this.setState(oldState=>{
            var data= oldState.lowerThirdData
            delete data[index]
            return{lowerThirdData: data}
        })
    }

    render() {
        var fields =this.state.lowerThirdData.slice()
        fields.push({naam:"",functie:""})
        return(

        <div className="Page">

            {
            this.state.showPopUp &&
            <div className="popUp">
                <PopUp text={this.state.textFile} addPerson={this.addRecord} items={this.state.lowerThirdData} close={()=> this.setState({showPopUp:false})}/>
            </div>
            }
            <div className="normalContent">
                <div className="firstRow">
                    <div className="fileInput blueBox">
                        <p>Importeer namen uit een CSV file</p>
                        <input  type="file" name="csvFile" accept=".csv" value={this.state.csvFile} onChange={this.handleInputChange}/>
                    </div>
                    <div className="fileInput blueBox">
                        <p>Selecteer namen uit een textfile</p>
                        <input  type="file" name="txtFile" accept=".txt" value={this.state.txtFile} onChange={this.handleInputChange}/>
                        {
                            this.state.txtFile!=="" && <a style={{cursor:"pointer"}} onClick={()=>{this.setState({showPopUp:true})}}><u>Klik hier om namen uit het bestand toe te voegen</u></a>
                        }
                    </div>
                    <div className="blueBox">
                        <p>Kies Logo</p>
                        <div className="logoChooser ">
                            {["wat","rtv"].map((value,index) => {
                                var path=`../../logo${value}110px.png`
                                return(<div className="logoOption">
                                            <img src={path}/>
                                            <input style={{height:"available",alignSelf:"center"}} type="radio" name="logoPath" key={index} value={path} checked={path===this.state.logoPath} onClick={this.handleInputChange}/>
                                        </div>)
                            })
                            }
                        </div>
                    </div>
                </div>


                <form className="fields">
                    <div className="headers">
                        <p className="number center">  </p>
                        <p className="naam center">Naam</p>
                        <p className="functie center">Functie</p>
                        <p className="options center">Options</p>
                    </div>

                        {fields.map((value,index) => {return(
                        <div className="fieldRow">
                            <p className="number">{index+1}</p>
                            <input className="naam" key={"naam "+index}  name ={"naam "+index} type="text" value={value.naam} onChange={this.changeField} />
                            <input className="functie" key={"functie "+index}  name ={"functie "+index} type="text" value={value.functie} onChange={this.changeField} />
                            <p  className="options"><i className="material-icons" onClick={()=> this.addRow(index)}>add</i>
                                {index===this.state.lowerThirdData.length||<i className="material-icons" onClick={()=> this.genereerLowerThirds([value],index)}>get_app</i>}
                                {index===this.state.lowerThirdData.length||<i className="material-icons" onClick={()=> this.deleteRow(index)}>delete</i>}
                            </p>
                        </div>)
                        })}

                </form>

                {this.state.lowerThirdData.length!==0&&
                    <div className="downloadButtons">
                        <button  className="DownloadButton" onClick={()=>this.genereerLowerThirds(this.state.lowerThirdData)}><i className="material-icons">get_app</i> Download Lower Thirds</button>
                        <button  className="DownloadButton" onClick={()=>this.downloadCSV(this.state.lowerThirdData)}><i className="material-icons">get_app</i> Download as CSV</button>
                    </div>
                }

                <canvas style={{backgroundColor:"black",display:"none"}} height="1080"  width="1920" ref={this.canvas} className="border"></canvas>

            </div>
            </div>)
    }
}
export default Page