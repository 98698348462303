import React ,{Component} from 'react'

class PopUp extends Component {

    constructor(){
        super();
        this.state={
            steps:["name","functie","add"],
            step:0,
            max:1,
            backUp:""
        }

        this.selectionChanges=this.selectionChanges.bind(this)
        this.handleInputChange=this.handleInputChange.bind(this)
        this.nextStep=this.nextStep.bind(this)

        this.keyIndex={}
        this.previouseSelection=""

        this.keyDownFunction=(event)=>{
            if(event.key==="Enter"||event.key==="Tab"){
                if(event.key==="Enter"){
                    event.preventDefault()
                }
                this.nextStep()
            }else if (event.key.match(/\w/) && event.key.length==1){
                this[this.getStep()+"Input"].current.focus()
            }else{
                this.keyIndex[event.key]=true
            }
        }

        this.keyUpFunction=(event)=>{
            this.keyIndex[event.key]=false
        }

        this.nameInput=React.createRef()
        this.functieInput=React.createRef()
    }

    getStep(){
        return this.state.steps[this.state.step]
    }


    selectionChanges(){
        var prevSelect=this.previouseSelection
        var text="";
        var selectedText=this.getSelectionText()
        this.previouseSelection=selectedText

        if(selectedText!==prevSelect){
            if(!this.keyIndex.Alt){
                this.setState({backUp:this.state[this.getStep()]})
            }

            var newBackup=""
            var regex

            try{
                 regex= new RegExp("^"+prevSelect.slice(0,-1))
            }catch {
                regex=new RegExp(".*")
            }
            if(!selectedText.match(regex) && this.keyIndex.Alt){

                if(!this.state.backUp.match(regex)){
                    newBackup=this.state.backUp+prevSelect+", "
                }else{
                    newBackup=prevSelect+", "
                }
                this.setState(oldState=>{console.log(oldState.backUp);return {backUp:newBackup}})
            }else if (this.keyIndex.Alt){
                newBackup=this.state.backUp
            }

            text=newBackup+selectedText

            if(text!=="" ){
                if(this.getStep()!=="add"){
                    var text=this.setKoppelTeken(text)
                    this.setState({[this.getStep()]:text.replace(/^./,text[0].toUpperCase())})
                }
            }
        }
    }

    setKoppelTeken(string){
        console.log(string)
        var newString=string.replace(/,\s([^,]+)$/, ' en $1')
        return newString
    }

    nextStep(){
        if(this.state.step<this.state.max){
            this.setState(oldState=>{
                return {step:oldState.step+1}
            })
        }else{
            this.props.addPerson(this.state.name,this.state.functie)
            this.setState(
                {step:0,name:"",functie:""}
            )
        }
    }

    componentDidMount() {
        document.addEventListener('keydown',this.keyDownFunction)
        document.addEventListener('keyup',this.keyUpFunction)
    }

    componentWillUnmount() {
        document.removeEventListener('keydown',this.keyDownFunction)
        document.removeEventListener('keyup',this.keyUpFunction)
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    getSelectionText(){
        var text = "";
        if (window.getSelection) {
            text = window.getSelection().toString();
        } else if (document.selection && document.selection.type != "Alt") {
            text = document.selection.createRange().text;
        }
        return text;
    }


    render() {
        var itemsLijst=this.props.items.slice()
        return(
            <div>
                <button onClick={this.props.close} >Close Pop-Up</button>
                <div className="popUpColums">
                    <pre style={{width: "100%"}} onMouseMove={this.selectionChanges} className="popUpText textColumn">{this.props.text}</pre>
                    <div className="selectColumn">
                        <label>Naam:<textarea autoComplete={true} ref={this.nameInput} type="text" style={{borderColor:this.state.step===0 && "darkgoldenrod"}} name="name" onChange={this.handleInputChange} value={this.state.name}/></label><br/>
                        <label>Functie:<textarea autoComplete={true} ref={this.functieInput} type="text" style={{borderColor:this.state.step===1 && "darkgoldenrod"}} name="functie" onChange={this.handleInputChange} value={this.state.functie}/></label>
                        <div className="buttons">
                            {this.getStep()!=="name" && <button onClick={()=>{this.setState(oldstate=>{return {step:oldstate.step-1}})}} >Back</button>}
                            <button onClick={this.nextStep} >{this.state.max===this.state.step?"Add":"Next"}</button>
                        </div>
                        <div style={{overflowY:"scroll",height:300}}>
                            {itemsLijst.reverse().map((value)=>{
                                return(<p>Naam: {value.naam} | Functie: {value.functie}</p>)
                            })}
                        </div>
                    </div>
                </div>

            </div>
        )

    }

}
export default PopUp