import React from 'react';
import logo from './logo.svg';
import './App.css';
import Page from './Page'

function App() {
  return (
      <Page />
  );
}

export default App;
